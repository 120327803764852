import { styled } from '../theme';

interface Props {
  primary: boolean;
}

export const Text = styled.p<Props>`
  font-size: 1em;
  color: ${props => (props.primary ? props.theme.color.primary : props.theme.color.info)};
`;
