import { styled } from '../theme';

export const Button = styled.button`
  font-size: 1.125em;
  margin: 1em;
  padding: 0.25em 1em;
  border-radius: 0.5em;

  background-color: ${props => props.theme.color.primary};
  color: ${props => props.theme.color.white};
  box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -1px rgba(0, 0, 0, 0.06);
`;
