import React from 'react';
import { connect } from 'react-redux';
import { Dispatch } from 'redux';

import {
  LoginData,
  loginFailed,
  loginRequest,
  LoginState,
  loginSuccess,
  UserData,
} from '../../store/login/';

interface LoginProps {
  readonly failed: (error: string) => void;
  readonly request: (input: LoginData) => void;
  readonly success: (user: UserData) => void;
  readonly user: UserData;
  readonly error: string;
  readonly isLoading: boolean;
}

const Login: React.FC<LoginProps> = props => {
  const { error, isLoading, user, failed, request, success } = props;

  const requestAction = () => {
    request({ email: 'test', password: 'test' });
  };

  const successAction = () => {
    success(user);
  };

  const failedAction = () => {
    failed(error);
  };

  return (
    <div>
      <button onClick={requestAction}>request</button>
      <button onClick={successAction}>success</button>
      <button onClick={failedAction}>failed</button>
      <div>{isLoading ? 'loading' : 'loaded'}</div>
    </div>
  );
};

const mapStateToProps = ({ userLogin }: { userLogin: LoginState }) => ({
  error: userLogin.error,
  isLoading: userLogin.isLoading,
  user: userLogin.user,
});

const mapDispatchToProps = (dispatch: Dispatch) => ({
  failed: (error: string) => dispatch(loginFailed(error)),
  request: (input: LoginData) => dispatch(loginRequest(input)),
  success: (user: UserData) => dispatch(loginSuccess(user)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Login);
