export interface LoginData {
  email: string;
  password: string;
}

export interface UserData {
  id: string;
  email: string;
  avatar: string;
}

export interface LoginState {
  user: UserData;
  isLoading: boolean;
  error: string;
}

export enum LoginActionsType {
  LOGIN_REQUEST = 'LOGIN_REQUEST',
  LOGIN_SUCCESS = 'LOGIN_SUCCESS',
  LOGIN_FAILED = 'LOGIN_FAILED',
}

export interface LoginRequest {
  type: LoginActionsType.LOGIN_REQUEST;
  input: LoginData;
}

export interface LoginSuccess {
  type: LoginActionsType.LOGIN_SUCCESS;
  user: UserData;
}

export interface LoginFailed {
  type: LoginActionsType.LOGIN_FAILED;
  error: string;
}

export type LoginActions = LoginRequest | LoginSuccess | LoginFailed;
