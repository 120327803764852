import React from 'react';
import { Provider } from 'react-redux';
import { ThemeProvider } from 'styled-components';

import store from './store';

import Card from './components/Card';
import Counter from './components/Counter';
import Login from './components/Login';

import { theme } from './utils/theme';

const App = () => {
  return (
    <Provider store={store}>
      <ThemeProvider theme={theme}>
        <Card>
          <Counter />
          <Login />
        </Card>
      </ThemeProvider>
    </Provider>
  );
};

export default App;
