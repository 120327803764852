import baseStyled, { ThemedStyledInterface } from 'styled-components';

export const theme = {
  color: {
    danger: '#dc3545' /* Pomegranate */,
    darkAccent: '#ac7742' /* Cape Palliser */,
    darkShade: '#252423' /* Shark */,
    info: '#17a2b8' /* Shark */,
    lightAccent: '#949b92' /* Stack */,
    lightShade: '#eeeee8' /* Cararra */,
    mainBrand: '#4f65e4' /* Shadow */,
    primary: '#4f65e4' /* Shadow */,
    success: '#38b2ac' /* Fruit Salad */,
    warning: '#ffc107' /* Zest */,
    white: '#ffffff' /* White */,
  },
};

export type Theme = typeof theme;
export const styled = baseStyled as ThemedStyledInterface<Theme>;
