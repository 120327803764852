import { Action } from 'redux';
import { CounterActions, CounterActionType, CounterState } from './types';

const initialState: CounterState = {
  count: 0,
  primary: false,
};

const count = (
  state: CounterState = initialState,
  action: Action | CounterActions
): CounterState => {
  switch (action.type) {
    case CounterActionType.INCREMENT:
      return { ...state, count: state.count + 1, primary: true };
    case CounterActionType.DECREMENT:
      return { ...state, count: state.count - 1, primary: false };
    default:
      return state;
  }
};

export default count;
