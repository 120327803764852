import React from 'react';
import { connect } from 'react-redux';
import { Dispatch } from 'redux';

import { CounterState, decrementCounter, incrementCounter } from '../../store/counter/';

import { Button } from '../../utils/theme/Button';
import { Text } from '../../utils/theme/Text';

interface CounterProps {
  readonly increment: VoidFunction;
  readonly decrement: VoidFunction;
  readonly count: number;
  readonly primary: boolean;
}

const Counter: React.FC<CounterProps> = props => {
  const { count, decrement, increment, primary } = props;
  return (
    <div>
      <Text primary={primary}>{count}</Text>
      <Button onClick={increment}>+</Button>
      <Button onClick={decrement}>-</Button>
    </div>
  );
};

const mapStateToProps = ({ counter }: { counter: CounterState }) => ({
  count: counter.count,
  primary: counter.primary,
});

const mapDispatchToProps = (dispatch: Dispatch) => ({
  decrement: () => dispatch(decrementCounter()),
  increment: () => dispatch(incrementCounter()),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Counter);
