import { LoginActions, LoginState } from './types';

const initialState: LoginState = {
  error: '',
  isLoading: false,
  user: { id: '', email: '', avatar: '' },
};

const login = (state: LoginState = initialState, action: LoginActions): LoginState => {
  switch (action.type) {
    case 'LOGIN_REQUEST':
      return { ...state, isLoading: true };
    case 'LOGIN_SUCCESS':
      return { ...state, isLoading: false, user: action.user };
    case 'LOGIN_FAILED':
      return { ...state, isLoading: false, error: action.error };
    default:
      return state;
  }
};

export default login;
