import { LoginActions, LoginActionsType, LoginData, UserData } from './types';

export const loginRequest = (input: LoginData): LoginActions => ({
  input,
  type: LoginActionsType.LOGIN_REQUEST,
});

export const loginSuccess = (user: UserData): LoginActions => ({
  type: LoginActionsType.LOGIN_SUCCESS,
  user,
});

export const loginFailed = (error: string): LoginActions => ({
  error,
  type: LoginActionsType.LOGIN_FAILED,
});
