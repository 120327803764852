import { applyMiddleware, combineReducers, compose, createStore } from 'redux';
import logger from 'redux-logger';
import thunk from 'redux-thunk';

import count from './counter/reducers';
import login from './login/reducers';

import { CounterState } from './counter/types';
import { LoginState } from './login/types';

const reducer = combineReducers({
  counter: count,
  userLogin: login,
});

export interface State {
  counter: CounterState;
  userLogin: LoginState;
}

const composeEnhancers = (window as any).__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

const configureStore = (initialState?: State) =>
  createStore(reducer, initialState, composeEnhancers(applyMiddleware(logger, thunk)));

const store = configureStore();

export default store;
